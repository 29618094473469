@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: auto;
  min-height: 100vh;
  background-color: #f3f3f3;
  --toastify-color-light: #ff6104;
  --toastify-text-color-light: #fff;
}

.NewMaskedInput .MuiInputBase-root.Mui-error {
  border: none;
}

.recaptcha-badge {
  min-height: 90px;
}

button,
a {
  text-decoration: none;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  outline: none;
}

button,
a.btn {
  text-decoration: none;
  border: none;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  cursor: pointer;
  outline: none;
}

.MuiAutocomplete-endAdornment {
  top: auto;
  bottom: auto;
}

button.normal {
  max-width: 163px;
}

button {
  margin-bottom: 32px;
}

ul,
ul * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.top-btn-1 {
  background: #ff6104;
  border: 1px solid #ff6104;
  color: #ffffff;
}

.top-btn-1:hover {
  background: #cc4c00;
  border: 1px solid #cc4c00;
}

.top-btn-2 {
  color: #000;
  background: #fff;
  border: 1px solid transparent;
}

.top-btn-2:hover {
  border: 1px solid #ff6104;
  box-sizing: border-box;
  font-weight: 900;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end;
}

.d-flex {
  display: flex !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-fill {
  -ms-flex: 1 1;
  flex: 1 1;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

#block-overlay,
.block-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 111;
}

.container-dropzone {
  padding: 40px;
  margin-top: 8px;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.container-dropzone svg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.container-dropzone path {
  fill: none;
  stroke: #cccccc;
  stroke-width: 3;
  stroke-dasharray: 8, 6;
}

.container-dropzone button {
  max-width: 297px;
}

.react-pdf__Page__annotations,
.annotationLayer {
  display: none;
}

*::-webkit-scrollbar {
  width: 12px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 12px;
}

*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiPickersModal-dialogRootWider h3.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-h3 {
  font-size: 16px;
}

.green {
  color: #1e854a;
}

.red {
  color: #eb0000;
}

.helperText {
  color: #787878;
  margin-top: 5px;
  display: block;
  padding-right: 5px;
  font-size: 13px;
  text-align: right;
}

/* .MuiTableSortLabel-root:not(.MuiTableSortLabel-active) {
  display: "none"
} */

.d-none {
  display: none !important;
}

.fc-toolbar {
  text-transform: capitalize;
}